<template>
  <div class="container">
    <!-- <header-back name='登录'></header-back> -->
    <div class="top44">
      <div
        class="login-tit"
        v-if="isModel"
      >
        <h5>手机号登录！</h5>
        <span
          @click="onSpan(2)"
          v-if="!icode"
        >商户登录
          <van-icon name="arrow" />
        </span>
      </div>
      <div
        class="login-tit"
        v-else
      >
        <h5>商户登录！</h5>
        <span @click="onSpan(1)">手机号登录
          <van-icon name="arrow" />
        </span>
      </div>
      <div class="cen">
        <van-field
          v-model="loginForm.username"
          v-if="!isModel"
          clearable
          placeholder="请输入商户账号"
        />
        <van-field
          v-model="loginForm.password"
          type="password"
          v-if="!isModel"
          placeholder="请输入商户密码"
        />
        <van-field
          v-model="loginForm.mobile"
          type="tel"
          clearable
          v-if="isModel"
          placeholder="请输入用户手机号码"
        />
        <van-field
          v-model="loginForm.captcha"
          v-if="isModel"
          center
          clearable
          maxlength="6"
          placeholder="请输入短信验证码"
        >
          <template #button>
            <span
              style="cursor: pointer;"
              :style="{color:variables.textColor}"
              @click="getVerify()"
              v-html="count"
            ></span>
          </template>
        </van-field>

        <van-field
          name="checkbox"
          :border='false'
        >
          <template #input>
            <van-checkbox
              v-model="checked"
              :checked-color="variables.textColor"
              icon-size="0.5rem"
              style="color: #8c8c8c"
            >我已经阅读并同意</van-checkbox>
            <span
              :style="{color:variables.textColor}"
              @click="protocol"
            >《使用协议》</span>
          </template>
        </van-field>
        <div style="margin: 16px">

          <van-button
            round
            block
            type="info"
            native-type="submit"
            :color="variables.textColor"
            @click="login()"
          >登录</van-button>
          <!-- <p style="font-size:16px;text-align: center;padding: 12px 0;">
            没有账号,<router-link
              class="login"
              tag="span"
              to="/register"
            >前往注册</router-link>
            <span
              style="padding-left:60px;font-size:12px"
              v-if="!isModel"
              @click="onPwd"
            >忘记密码</span>
          </p> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import HeaderBack from "../../components/header-back";
import { Toast, Dialog } from "vant";
import variables from "@/styles/variables.scss";
import API from "../../api/http";
import validateRule from "@/utils/validateRule";
import { getRegionIu } from "@/utils/common.js";
import utils from "../../utils/index";
export default {
  components: { HeaderBack },
  data() {
    return {
      loginForm: {
        mobile: "",
        captcha: "",
        username: "",
        password: "",
      },
      isModel: true,
      timer: null,
      count: "发送验证码",
      checked: true,
      typeVal: 1,
      icode: getRegionIu(),
      address: "",
    };
  },
  computed: {
    variables() {
      return variables;
    },
  },
  created() {
    console.log("kk", utils.getStore("address"));
    if (utils.getStore("address")) {
      this.address = utils.getStore("address");
    }
  },
  methods: {
    getAddress() {
      let that = this;
      var geolocation = new BMap.Geolocation();
      geolocation.getCurrentPosition(function (r) {
        if (this.getStatus() == BMAP_STATUS_SUCCESS) {
          that.getCity(r.point);
        }
      });
      return new Promise((resolve) => {
        setTimeout(() => resolve(that.address), 1000);
      });
    },
    getCity(point) {
      let that = this;
      var point = new BMap.Point(point.lng, point.lat); //用所定位的经纬度查找所在地省市街道等信息
      var gc = new BMap.Geocoder();
      gc.getLocation(point, function (rs) {
        // var addComp = rs.addressComponents;

        that.address = rs.address;
      });
    },
    onSpan(val) {
      this.typeVal = val;
      this.isModel = true;
      if (val === 2) {
        this.isModel = false;
      }
    },
    getVerify() {
      // 验证手机号
      if (!this.loginForm.mobile) {
        Toast("请输入手机号码");
        return;
      }
      if (!validateRule.checkPhoneAndTel(this.loginForm.mobile)) {
        //手机号格式不正确
        Toast("手机号码输入错误");
        return;
      }
      const TIME_COUNT = 60; // 更改倒计时时间
      if (!this.timer) {
        // 发送验证码
        this.count = TIME_COUNT;
        API.postSendSms({ mobile: this.loginForm.mobile })
          .then((response) => {
            Toast("发送成功");
            this.timer = setInterval(() => {
              if (this.count > 0 && this.count <= TIME_COUNT) {
                this.count--;
              } else {
                clearInterval(this.timer); // 清除定时器
                this.count = "重新获取";
                this.timer = null;
              }
            }, 1000);
          })
          .catch((err) => {
            Toast(err.errmsg);
          });
      }
    },
    async login() {
      if (this.isModel) {
        if (!this.loginForm.mobile) {
          Toast("请输入手机号码");
          return;
        }
        if (!this.loginForm.captcha) {
          Toast("请输入验证码");
          return;
        }
        if (!validateRule.checkPhoneAndTel(this.loginForm.mobile)) {
          //手机号格式不正确
          Toast("手机号码输入错误");
          return;
        }
        if (this.loginForm.captcha.length < 6) {
          Toast("验证码输入错误");
          return;
        }
      } else {
        if (!this.loginForm.username) {
          Toast("请输入账号");
          return;
        }
        if (!this.loginForm.password) {
          Toast("请输入密码");
          return;
        }
      }

      if (!this.checked) {
        Toast("请勾选同意协议");
        return;
      }
      // if (!this.address) {
      //   this.address = await this.getAddress();
      // }
      Toast.loading({
        message: "登录中...",
        forbidClick: true,
        duration: 0,
      });
      this.loginForm.location = this.address;
      console.log("kkk", this.typeVal);
      // type 1 正常用户 2 渠道用户
      this.$store
        .dispatch("user/login", {
          ...this.loginForm,
          type: this.typeVal,
          mobileType: 1,
        })
        .then((res) => {
          Toast.clear();

          this.$router.replace("/person");
        })
        .catch((err) => {
          Toast.clear();
          Toast(err.errmsg);
        });
    },
    protocol() {
      Dialog.alert({
        title: "平台用户服务协议",
        messageAlign: "left",
        message: `
<p style="margin:0">积分商城(以下简称 “本平台”)的所有权及运营权归本平台所有。</p>
<p style="margin:0">《平台用户服务协议》(以下简称“本协议” ）是指用户（注册、登录、使用、浏览本服务的个人或组织)与本平台所订立的有法律效力的协议。</p>
<p style="margin:0">在此特别提醒用户认真阅读、充分理解——本协议中各条款，包括 但不限于免除、限制平台责任的免责条款及对用户的权利限制条款。请 用户审慎阅读并选择接受或不接受本协议(未成年人应在法定监护人陪 同下阅读)。除非用户接受本协议所有条款,否则用户无权注册、登录 或使用本协议项下服务。用户的注册、登录、使用等行为将视为对本协 议的接受,并同意接受本协议各项条款的约束。</p>
<p style="margin:0">一、本平台服务条款的确认和接纳</p>
<p style="margin:0">1.1 用户应当认真阅读本协议,对于协议中的内容和涉及重要权益 的内容,用户应当加以重点阅读。如果用户对本协议中的某一或某些条 款产生疑问,用户应当向本平台咨询。如果用户不同意本协议中的某一 或某些条款,用户应当立即停止注册、登录及使用等行为;</p>
<p style="margin:0">1.2 本协议为电子形式的协议,是处理用户与本平台一切权利义务 关系的约定,对双方均具有法律约束力;</p>
<p style="margin:0">1.3 本平台上已经发布的或将来可能发布或更新的相关协议、各类 规则等补充内容,是本协议不可分割的组成部分,与本协议正文具有同等 法律效力。因此,当用户完全接受本协议时,当然且确定的表明用户也完 全接受本协议的补充条款;</p>
<P style="margin:0">1.4 本平台有权根据需要修改本协议及完善规则,并在本平台公示 的方式进行公告,不再单独通知用户。修订后的协议或将来可能发布或 更新的各类规则一经在平台公布后,立即自动生效。如用户不同意相关 修订,应当立即停止使用本平台服务。用户继续使用本平台服务,即表示 用户接受经修订的协议或规则。</P>
<p style="margin:0">二、用户使用规则及风险严控措施</p>
<p style="margin:0">2.1 用户需要在本平台注册后通过对应账号及密码才能登录本平 台;若用户想使用本平台服务功能,用户必须注册相应帐户并接受本协 议约定所有条款。用户可以按照本平台说明随时终止使用用户的账户, 本平台将会依据本协议规定保留或终止用户的账户。用户必须承诺和保 证遵守如下准则:</p>
<p style="margin:0">2.1.1 未成年人除非在法定监护人的陪同下了解并同意本协议后方 可注册,否则本平台有权立即停止提供服务,用户或用户对应的法定监护 人承担由此而产生的一切损失及法律责任，本平台不承担由此而产生的 一切损失及法律责任;</p>
<p style="margin:0">2.1.2 用户了解并理解，注册本平台的用户必须是具有完全民事行 为能力的自然人,无民事行为能力、限制民事行为能力人不得注册为本 平台用户、不得超过其民事权利或行为能力范围在本平台进行交易,否 则,本平台有权釆取取消订单、冻结或关闭账户、拒绝提供服务等措施, 给本平台及相关方造成损失的,用户应承担赔偿责任;</p>
<p style="margin:0">2.1.3 用户了解并同意,用户须对注册信息的真实性、合法性、有效 性承担全部责任;用户不得冒充他人,不得利用他人的名义发布任何信息; 不得恶意使用注册帐户误导其他用户;否则本平台有权立即停止提供服 务,用户独自承担由此而产生的一切法律责任;</p>
<p style="margin:0">2.1.4 用户应该根据本平台系统的要求填写必要的注册信息和账户 信息,并保证信息的合法性、真实性、及时性和有效性。注册成功后,如 果用户所填写的信息发生改变,用户应当及时登录本平台平台变更相关 内容;</p>
<p style="margin:0">2.1.5 用户使用本平台及平台服务的行为必须合法合规,用户必须 为自己的注册帐户下一切行为负责,包括用户所提供的礼品卡券或权益 推广行为承担所有法律责任。用户应对其中的礼品卡券出售或自身推广 行为严格要求，并承担因提供的礼品卡券交易服务而引起的所有法律责 任,包括因对礼品卡券或推广行为的正确性、完整性及合法性的依赖而 产生的风险。本平台无法且不会对因用户行为而导致的任何损失或损害 承担责任。</p>
<p style="margin:0">2.1.6 初始申请用户是账户合法使用权人，用户对其登录信息保密、 不被其他人获取并使用，并且对用户在本平台账户下的所有行为负责。用户必须将任何有可能触犯法律的,未授权使用或怀疑为未授权使用的 行为在第一时间通知本平台,本平台不对用户因未能遵守上述要求而造 成的损失承担责任;</p>
<p style="margin-bottom:10px">2.1.7 用户不得将在本平台注册获得的账户借给他人使用,否则用 户应承担由此产生的全部责任,并与实际使用人承担连带责任。如果用 户是在监护人参与下完成用户注册的不具备完全民事权利能力或完全 民事行为能力的自然人,用户及用户的监护人同意为在该用户名下所发 生的一切行为负责;</p>
<p style="margin:0">2.2 在本平台对用户使用平台服务提交的订单请求行为进行核查 时，本平台提前 24 小时知会用户将进行核查,用户应配合提供本平台 要求的相应凭证;</p>
<p style="margin:0">2.3 用户保证所提交的订单请求正当合法，不会违反法律法规规 定，也不会侵犯任何第三人的合法利益。同时用户须保证其兑换指令中 的信息真实、合法、准确，并承担因信息不真实、不合法等原因所导致 的本平台、用户和其他第三方所有损失。因用户网点提供的信息不准确 导致兑换失败或迟延兑换的，本平台不承担责任。本平台不介入因用户 原因导致的所有用户与网点、用户之间的纠纷，也不对此承担任何责任;</p>
<p style="margin:0">2.4 甲、乙双方均承诺采取合理的风险防范措施，以尽量避免或减 小风险。用户应保证严格遵守政策法规，不得进行套现、虚假交易、洗 钱等非法行为;</p>
<p style="margin:0">2.5 在合作期间，用户有义务采取相关措施对用户网点开展身份识 别，了解网点交易的背景，确保自身或网点不通过平台服务进行套现、 洗钱、销赃、第三方欺诈等违法活动。本平台怀疑用户网点与用户的交 易行为涉及洗钱等违法行为而展开调查的，用户有义务在能力范围内提 供必要的协助;用户需尽力配合案件协查，并提供相关信息;如果相关 安全事件频繁出现，本平台认为风险过大，本平台有权暂停平台为用户 提供的各项服务能力，本平台有权暂停结算风险案件所涉款项，且不承 担任何责任;如因此造成本平台损失的，本平台有权要求用户根据本协 议相关约定承担赔偿责任;</p>
<p style="margin:0">2.6 若客户拒绝承认积分兑换交易而发生退还用户积分的情况，本 平台与用户双方应相互积极配合进行责任的认定，如认定为用户原因造 成的则由用户承担经济损失并承担先行赔付责任;如认定为本平台原因 造成的则由本平台承担对应经济损失;</p>
<p style="margin:0">2.7 如用户或用户网点违反国家法律、法规、政策或法令等情形而 造成本平台或用户损失的，用户应自行解决上述情况而导致的索赔等纠 纷;若用户怠于解决，本平台有权根据自身独立的判断直接从应付给用 户的结算款项中扣回与本平台损失金额同等的金额，用户的结算款项不 足以抵扣本平台损失金额时，本平台有权立即暂停、中止或终止向用户 提供本协议项下的服务，对于用户因此遭受的损失不承担任何责任，同 时保留向用户追索剩余款项的权利。</p>
<p style="margin:0">三、用户使用义务:</p>
<p style="margin:0">3.1 用户承诺对网络服务的使用应符合国家法律法规的规定，且行为符合下列要求:</p>
<p style="margin:0">● 用户不得通过任何手段恶意使用平台各项服务，包括但不仅限于以牟利、炒作等为目的的多个账户注册登录。用户亦不能盗用其他用户 账户;</p>
<p style="margin:0">● 从中国境内向境外传输技术性资料时必须符合中国有关法律的规定;</p>
<p style="margin:0">● 不传输或发表:煽动抗拒、破坏宪法和法律、行政法规实施的言论，煽动颠覆国家政权，推翻社会主义制度的言论，煽动分裂国家、破 坏国家统一的的言论，煽动民族仇恨、民族歧视、破坏民族团结的言论;</p>
<p style="margin:0">● 不传输或发表任何违法犯罪的、骚扰性的、中伤他人的、辱骂性的、恐吓性的、伤害性的、庸俗的，淫秽的、不文明的等信息资料;</p>
<p style="margin:0">● 不干扰本平台的正常运转，不得侵入本平台及国家计算机信息系统;</p>
<p style="margin:0">● 从中国境内向境外传输技术性资料时必须符合中国有关法律的规定;</p>
<p style="margin:0">● 不传输或发表:煽动抗拒、破坏宪法和法律、行政法规实施的言论，煽动颠覆国家政权，推翻社会主义制度的言论，煽动分裂国家、破坏国家统一的的言论，煽动民族仇恨、民族歧视、破坏民族团结的言论;</p>
<p style="margin:0">● 不传输或发表任何违法犯罪的、骚扰性的、中伤他人的、辱骂性 的、恐吓性的、伤害性的、庸俗的，淫秽的、不文明的等信息资料;</p>
<p style="margin:0">● 不干扰本平台的正常运转，不得侵入本平台及国家计算机信息系 统;</p>
<p style="margin:0">● 不得传输或发表违背公序良俗和涉及国家安全的信息资料或言 论;</p>
<p style="margin:0">● 遵守所有使用网络服务的网络协议、规定、程序和惯例;</p>
<p style="margin:0">● 使用网络服务不作非法用途。如用户违反上述规定，则平台有权直接采取一切必要措施，包括但不仅限于暂停或查封用户的账号，追求用户的相关法律责任等。</p>
<p style="margin:0">3.2 用户必须知悉并确认:</p>
<p style="margin:0">● 本平台因业务发展需要,拥有单方面对本服务的全部或部分服务 内容在任何时候不经任何通知的情况下变更、暂停、限制、终止或撤销 本平台服务的权利,用户需自行承担此风险;</p>
<p style="margin:0">● 本平台有权自行全权决定以任何理由,对违反有关法律法规或本 协议约定;或侵犯、妨害、威胁任何人权利或安全的内容,或者假冒他人 的行为,有权依法停止传输任何相关内容,并有权依其自行判断对违反本 协议的任何人士采取适当的法律行动,包括但不限于,从服务中删除具有 违法性、侵权性、不当性等内容，终止违反者的成员资格,阻止其使用 本平台全部或部分服务,并且依据法律法规保存有关信息并向有关部门 报告等;</p>
<p style="margin:0">四、商品信息</p>
<p style="margin:0">4.1 本平台上所发布的一切商品信息,包括但不限于图片、视频、文 字说明,均供用户决定是否转让礼品卡券或推广本平台上架权益,一旦用 户在决定转让礼品卡券或推广权益行为后通过平台系统提交订单,即表 明用户向本平台发出了明确的出售该礼品卡券或权益推广并接受承担 法律责任;</p>
<p style="margin:0">4.2 本平台上的商品回收或权益推广的价格、数量、是否回收等商 品信息随时都有可能发生变动,本平台不作特别通知。由于平台上商品 信息的数量极其庞大,虽然本平台会尽最大努力保证用户所浏览商品信息的准确性,但由于众所周知的互联网技术因素等客观原因存在,本平台 显示的信息可能会有一定的滞后性或差错,对此情形用户知悉并理解;</p>
<p style="margin:0">4.3 当用户通过本平台系统提交订单后,商品信息发生变化的,用户 不得以变更后的商品信息主张权利或要求本平台承担责任;</p>
<p style="margin:0">4.4 因平台系统或互联网技术性问题导致商品信息发生错误的,本 平台有权视情况取消订单或作其它处理;</p>
<p style="margin:0">4.5 本平台可以根据市场变化、市场竞争及国家政策的规定需要不 时的变更回收或权益推广折扣。如果价格变更发生在用户提交订单之前, 用户所提交的商品的价格以变更后的价格为准。如果用户提交订单后发 生了价格变更,用户所提交的商品的价格以用户提交订单时的价格为 准。</p>
<p style="margin:0">五、订单服务内容</p>
<p style="margin:0">5.1 如果用户使用本平台相关服务,请务必依据系统程序填写并提 交订单。当用户提交订单后,平台系统将自动向用户账户信息中进行确 认。无论用户所收到确认信息如何描述,该确认信息仅表示本平台已经 收到用户的订单要约;</p>
<p style="margin:0">5.2 本平台特别提醒用户,请用户在向本平台提交的礼品卡券交易 服务卡号与卡密或权益推广的订单之前,务必认真了解平台商品信息。 在订单提交成功之后,用户不得以不了解平台规则和商品的相关信息而 主张撤销订单;</p>
<p style="margin:0">5.3 当用户使用本平台提供的礼品卡券交易服务或相关服务时,请 务必依据本协议及系统程序进行操作。用户不得以不了解本协议及系统 程序为由主张订单无效或可撤销;</p>
<p style="margin:0">5.4 本平台收到用户的订单后,将会及时审核订单。用户必须承诺所 提交的礼品卡券或推广权益订单是合法的、完整的,保证所提供的身份 信息真实完整,无侵犯他人权益的行为,并愿意接受监管部门的监管,并 承担相应法律责任;如我站对用户提交的礼品卡券的合法性存在疑义,我 站有权向用户通过包括但不限于电话、邮件等方式进行礼品卡券来源合 法性的核实;</p>
<p style="margin:0">5.5 经本平台审核无误,本平台将安排打款。如果本平台认为订单存 在疑问(如用户提交的礼品卡券错误、非法等),本平台均有权取消订 单,或通过用户登记的邮件、电话、微信等沟通渠道任一种方式通知用 户;</p>
<p style="margin:0">5.6 本平台根据本协议的规定拒绝、取消或不接受订单的,用户同意 自行承担一切责任,但是有特别规定的除外;</p>
<p style="margin:0">5.7 本平台的受理礼品卡券订单时效权益推广服务订单时效和打 款时效均为一般处理时间,该时间仅作为用户提供的礼品卡券交易服务 的参考因素。</p>
<p style="margin:0">六、用户消费卡、电子卡、预付卡交易 如用户需要使用本平台所提供的礼品卡券交易服务,用户需遵守以 下内容:</p>
<p style="margin:0">6.1 本平台工作人员不会向用户索取任何礼品卡券资料,如果用户 泄露任何关于礼品卡券的信息,本平台概不负责也不承担任何法律责 任;</p>
<p style="margin:0">6.2 礼品卡券交易过程中,用户账号因自身原因使礼品卡券被盗取 或封存导致不能审核的责任由用户承担,并且将直接撤单;</p>
<p style="margin:0">6.3 礼品卡券交易过程中,如果用户私下将礼品卡券信息交易给买 家,并再次出售给本平台,如若出现,造成的损失由用户承担,本平台并有 权冻结用户账户信息处罚用户;</p>
<p style="margin:0">6.4 礼品卡券交易在未给用户打款期间出现问题的话,用户应积极 配合解决问题,根据要求本平台将延迟打款,如果问题没有解决本平台将 按责任轻重来加以处理;</p>
<p style="margin:0">6.5 严禁在本平台提交违法所得卡券及违法推广权益订单行为，如 发现核实属实，本平台有权暂停结算，关停账户服务等一切有必要措施 维护市场秩序，打击违法违规行为套利行为。</p>
<p style="margin:0">七、权益推广服务协议</p>
<p style="margin:0">7.1 用户不得采取任何作弊行为，如用户行为数据出现明显异常， 包括但不限于短时间内同一设备多次重复使用等，本平台有权要求用户 进行解释说明。如用户存在作弊或主动支持第三方作弊行为，及其他数 据造假的情形，视为用户严重违约，本平台有权解除协议并不予支付相 应费用。</p>
<p style="margin:0">7.2 用户不得进行刷量行为欺骗本平台，如本平台发现用户有上述 情形的，视为用户严重违约，本平台有权解除协议并不予支付相应费用。</p>
<p style="margin:0">7.3 用户作为本平台的合作推广方，应秉承着与本平台良好合作的 商务关系，积极维护本平台的品牌形象，不得作出损害本平台声誉的行为。</p>
<p style="margin:0">7.4 用户承诺对投放渠道具有合法有效的资质，其在本协议项下的 行为不侵害任何第三方的合法权益，否则应承担赔偿责任。</p>
<p style="margin:0">7.5 用户应按本平台要求进行推广，未经本平台书面同意，不得擅 自下架或变更推广内容。</p>
<p style="margin:0">7.6 本平台如对推广情况存在异议并提出整改通知的，用户应在收 到本平台通知后的一个工作日内按照本平台要求进行整改。</p>
<p style="margin:0">7.7 用户应保证用户的推广形式依法进行，若因用户推广形式违反 法律法规要求的，由用户承担相应法律责任，并赔偿因此给本平台或本 平台用户造成的一切损失。</p>
<p style="margin:0">八、责任限制与不可抗力</p>
<p style="margin:0">8.1 在法律法规所允许的限度内,因使用本平台服务而引起的任何 损害或经济损失,本平台承担的全部责任均不超过就用户所提交的礼品 卡券与该索赔有关的礼品卡券所实际获得的直接收益。这些责任限制条 款将在法律所允许的最大限度内适用,并在用户账户提交订单请求后永 久有效;</p>
<p style="margin:0">8.2 本平台仅限在“按现状”和“按现有”的基础上,向用户提供全部 的信息、内容、材料、产品(包括软件)和服务。除非另有明确的书面说 明,本平台不对其包含在第三方平台网站上的信息、内容、材料、产品(包 括软件)或服务作任何形式的、明示或默示的担保;</p>
<p style="margin:0">8.3 本平台不希望出现任何因交易而与用户之间产生纠纷,但并不 能够保证不发生该类纠纷。对于因前述各类情形而产生的任何纠纷(如 用户所持卡券为非法或被盗、泄露等),将依据中华人民共和国现行的有 关法律通过适当的方式合理解决;对于因此类交易而产生的各类纠纷之 任何责任和后果由用户承担,本平台不对用户违法行为承担任何责任及 后果。如果任何单位或者个人通过上述“信息”而进行任何交易或者进行 其他行为,须得知网络交易、网上行为的风险性,进行交易或行为的事前 应辨别和釆取谨慎的预防措施,本平台对任何损失或任何由于使用本平 台而引起的损失,包括但不限于直接的,间接的,偶然的、惩罚性的和引发 的损失,不承担责任;</p>
<p style="margin:0">8.4 本平台审核只是针对用户提供信息进行审核,特别是虚拟商品 来源本平台无法审核,自审核完毕发送给买家后,即已完成(如若遇到盗 刷卡券、黑卡券等任何非法形式获得的,本平台有权冻结该账户并将用 户信息提交给相关部门。)本平台不对商品来源负责,亦不承担任何法律 责任;</p>
<p style="margin:0">8.5 不论在任何情况下,本平台均不对由于互联网设备正常维护,互 联网络连接故障,电脑、通讯或其他系统的故障,电力故障,罢工,暴乱,骚 乱,灾难性天气(如火灾、洪水、风暴等),爆炸,战争,政府行为,司法行政 机关的命令或第三方的不作为而造成的不能履行或延迟履行承担责任。</p>
<p style="margin:0">九、协议终止</p>
<p style="margin:0">9.1 本平台有权经通知用户且无需用户认可后终止本协议,用户有 权请求本平台作出终止本协议的决定;</p>
<p style="margin:0">9.2 发生下列情形之一的,本平台有权立即终止协议:</p>
<p style="margin:0">● 本协议发生变更后,用户明确通知本平台不愿意接受新协议或变 更内容的;</p>
<p style="margin:0">● 本平台发现用户恶意注册用户,扰乱正常经营秩序的;</p>
<p style="margin:0">● 本平台发现用户多次恶意提交礼品卡券,扰乱正常经营秩序的;</p>
<p style="margin:0">● 用户注册账户，连续 3 个月不使用该账户的;</p>
<p style="margin:0">● 用户通过本平台进行违法犯罪活动的;</p>
<p style="margin:0">9.3 协议终止补充:</p>
<p style="margin:0">● 协议终止后,用户在本平台上的积分、账户余额均失效,本平台不 予兑现;</p>
<p style="margin:0">● 协议终止后,用户同意本平台在 1 年内继续保留用户的注册信息、 账户信息以及交易记录;</p>
<p style="margin:0">● 协议终止后,本平台有权向他人开放用户所注册使用的用户名; </p>
<p style="margin:0">● 如果用户在协议终止之前存在违反本协议的行为,本平台有权依 据本协议追究用户的法律责任。</p>
<p style="margin:0">十、适用的法律和管辖权</p>
<p style="margin:0">10.1 如果本协议的相关规定违反中华人民共和国法律法规的相应 条款,则适用中华人民共和国法律法规。如果本协议的相关规定违反中 华人民共和国的相关商业惯例,则仍然以本协议为准。</p>
<p style="margin:0">10.2 因本协议发生的任何争议,本平台与用户应当友好协商,协商 不成的,任何一方均有权向本平台住所所在地有管辖权的人民法院起诉, 其它任何法院均不具有管辖权。</p>
<p style="margin:0">十一、拒绝担保与免责声明</p>
<p style="margin:0">11.1 本平台对在平台内提供的所有服务不提供任何明示或默示的 担保或保证，包含但不限于商业适售性、特定目的之适用性及未侵害他 人权利等担保或保证。</p>
<p style="margin:0">11.2 用户理解并同意:基于以下原因而造成的，包括但不限于利 润、信誉、应用、数据损失或其他无形损失，平台不承担任何直接的、 间接的、附带的、特别、衍生性的或惩罚性的赔偿责任(即使平台事先 已被告知发生此种赔偿可能性亦然):</p>
<p style="margin:0">● 本服务无法使用;</p>
<p style="margin:0">● 为替换通过本服务购买或取得之任何商品、数据、信息、服务， 或缔结的交易而发生的成本;</p>
<p style="margin:0">● 用户的传输数据遭到未获授权的存取或篡改;</p>
<p style="margin:0">● 任何第三方在本服务中所作之声明或行为，或与本服务相关的其 他事宜，但本服务条款有明确规定的除外。</p>
<p style="margin:0">11.3 平台对本服务下涉及的网络故障、技术缺陷、覆盖范围限制、 不可抗力、计算机病毒、黑客攻击、用户所在位置、用户关机或其他非 本服务技术能力范围内的事因等造成的服务中断、内容丢失、出现乱码 不承担责任</p>
<p style="margin:0">11.4 平台将尽力维护本服务的安全性及方便性，但对服务过程中 出现的信息(包括但不限于用户发送的信息)删除或储存失败不承担任 何责任。另外平台保留判定用户的行为是否符合本协议要求的权利，如 用户违背了本协议内任意条款，平台有权终止对用户提供本服务。</p>
<p style="margin:0">11.5 相关第三方平台或应用内的信息、服务等由第三方提供。该 第三方平台或应用内信息、服务等的合法性、安全性以及真实性，应由 该第三方平台或应用经营者负责。用户应审慎地判断该第三方平台或应 用内服务的合法性等。用户理解并同意:出现如下事项，用户应自行与 该第三方平台或应用的经营者进行协商解决，不会因此向中国移动追究 任何形式的法律责任:</p>
<p style="margin:0">● 第三方平台、网站或应用出现侵害用户的利益的行为违反相关法 律法规或本条款的约定;</p>
<p style="margin:0">● 第三方平台、网站或应用使用用户的手机号码信息时产生的纠纷;</p>
<p style="margin:0">● 用户在使用第三方平台、网站或应用过程中遭受的损失;</p>
<p style="margin:0">● 第三方平台、网站或应用侵害用户个人利益的其他情况。</p>
<p style="margin:0">本合同一经同意即构成对双方合法、有效并可依本合同之条款强制执行的义务。</p>
`,
      }).then(() => {
        // on close
      });
    },
    onPwd() {
      //忘记密码
    },
  },
};
</script>
<style lang='scss' scoped>
@import "../../styles/variables.scss";
.container {
  height: 100%;
  background-color: #fff;
  padding: 0 15px;
}
.cen {
  .van-cell {
    line-height: 36px;
  }
  .login {
    color: $textColor;
  }
}
.login-tit {
  padding: 12px;
  h5 {
    font-size: 28px;
  }
  & > span {
    padding-top: 6px;
    font-size: 16px;
    cursor: pointer;
  }
}
</style>
