export function getQueryString(name) {
  console.log('getQueryString', name)
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
  var r = window.location.search.substr(1).match(reg);
  if (r != null) return unescape(r[2]);
  return null;
}
export function getRegionIu() {
  let iu = undefined
  console.log('getRegionIu', getQueryString('icode'))
  if (getQueryString('icode')) {
    iu = Number(getQueryString('icode'))
  }

  return iu
}